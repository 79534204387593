import { useDialog } from "shared/dialog/use-dialog";
import { ChangeProjectOwnerDialog } from "./change-project-owner-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { useTranslation } from "react-i18next";
import { ProjectUserResponse, PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg, usePutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminMutation } from "api/generatedApi";

interface Props {
  projectId: string;
  users: ProjectUserResponse[];
}

export interface UserIdToUserName {
  userId: string | undefined;
  userName: string | null | undefined;
}

export function useChangeProjectOwner(props: Props) {
  const { projectId, users } = props;
  const [openDialog, closeDialog] = useDialog(ChangeProjectOwnerDialog);

  const [changeProjectOwner] = usePutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminMutation();

  const { t } = useTranslation();

  const wrapMutation = useMutation({
    onSuccess: closeDialog,
    successProps: {
      description: t("dashboard.users.projectOwnerChangedSuccess"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit = (data: PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg) => {
    wrapMutation(
      changeProjectOwner({
        projectId: data.projectId,
        userIdOfNewOwner: data.userIdOfNewOwner,
      })
    );
  };

  const handleClick = (): void => {
    openDialog({
      userIdToUserNames: users
        .filter((x) => (x.role == "Manager" || x.role == "Owner") && x.id != "00000000-0000-0000-0000-000000000000")
        .map((u) => {
          return {
            userId: u.id,
            userName: u.name,
          };
        }),
      currentOwner: users.filter((u) => u.role == "Owner")[0].id ?? "",
      projectId,
      onSubmit: handleOnSubmit,
    });
  };

  return handleClick;
}
