import React, { useState } from "react";
import { Box, Button, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg } from "api/generatedApi";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { useTranslation } from "react-i18next";
import { DialogBaseProps } from "shared/dialog/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserIdToUserName } from "./use-change-project-owner";

interface Props extends DialogBaseProps {
  projectId: string;
  currentOwner: string;
  userIdToUserNames: UserIdToUserName[];
  onSubmit: (data: PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg) => void;
}

export const ChangeProjectOwnerDialog = (props: Props) => {
  const { projectId, currentOwner, userIdToUserNames } = props;
  const { t } = useTranslation();

  const {
    formState: { isSubmitting, isValid },
    register,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg>({
    mode: "all",
    defaultValues: {
      projectId: projectId,
      userIdOfNewOwner: currentOwner,
    },
  });

  const onSubmit: SubmitHandler<PutApiProjectsByProjectIdUsersAndUserIdOfNewOwnerAdminApiArg> = async (data) => {
    await props.onSubmit(data);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  if (!projectId) return null;

  register("projectId");
  setValue("projectId", projectId);

  return (
    <Dialog maxWidth="sm" open={props.isOpen}>
      <DialogTitleStyled title={t("dashboard.users.changeProjectOwnerDialog.title")} onClose={props.onClose} isOpen={props.isOpen} />
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="caption" color={"primary.main"}>
          {t("dashboard.users.changeProjectOwnerDialog.description")}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, pt: 1 }}>
          <FormControl sx={{ my: 1, width: "50%" }}>
            <InputLabel id="change-project-label">{t("dashboard.users.changeProjectOwnerDialog.inputLabel")}</InputLabel>
            <Select
              sx={{ my: 1 }}
              labelId="change-project-label"
              defaultValue={currentOwner ?? 0}
              {...register("userIdOfNewOwner", {
                validate: (value) => {
                  return value != currentOwner;
                },
              })}
            >
              {userIdToUserNames.map((x) => (
                <MenuItem key={x.userId} value={x.userId}>
                  {x.userName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={isSubmitting || !isValid} onClick={handleSubmit(onSubmit)}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
